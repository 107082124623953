import { X, Loader2, Home, Mail, BellRing, Users, Palette, LogOut, MoreHorizontal, Info, ChevronDown, ChevronUp, ChevronLeft, ChevronRight, Link, Copy, Check, FileUp, Trash, } from 'lucide-react';
export const iconLibrary = {
    x: X,
    loader: Loader2,
    home: Home,
    mail: Mail,
    bell: BellRing,
    users: Users,
    palette: Palette,
    logout: LogOut,
    option: MoreHorizontal,
    info: Info,
    chevronDown: ChevronDown,
    chevronUp: ChevronUp,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    link: Link,
    copy: Copy,
    check: Check,
    fileUp: FileUp,
    trash: Trash,
};
