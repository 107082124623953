import { useEffect, useState } from 'react';
import { CheckBox } from '../CheckBox';
import { useGetEmails } from '../../../hooks';
import { useTranslation } from '../../../../_shared/hooks';
interface CatchAllCheckBoxProps {
  isCatchAllChecked: boolean;
  setIsCatchAllChecked: (value: boolean) => void;
  inputLabel: string;
  emailId?: string;
}

export const CatchAllCheckBox: React.FC<CatchAllCheckBoxProps> = ({
  isCatchAllChecked,
  setIsCatchAllChecked,
  inputLabel,
  emailId,
}) => {
  const { data: emailData, isLoading } = useGetEmails({ fromCache: true });
  const [currentCatchAllEmailId, setCurrentCatchAllEmailId] = useState('');
  const t = useTranslation();

  useEffect(() => {
    if (!isLoading && emailData) {
      //check if one of the emails has isCatchAll set to true and set it to the state
      setCurrentCatchAllEmailId(
        emailData.find(email => email.isCatchAll)?.id ?? '',
      );
    }
  }, [emailData, isLoading]);

  // if the current email is not the catch all email, don't show the checkbox
  if (currentCatchAllEmailId && currentCatchAllEmailId !== emailId)
    return (
      <>
        <div className="bg-slate-100 opacity-50 w-full h-full absolute z-10" />
        <div className="text-sm">
          {t(
            'dashboard.emailEditTabs.tabs.general.withInbox.catchAll.hintAlreadySet',
          )}
        </div>
      </>
    );

  return (
    <CheckBox
      data-testid="catch-all-checkbox"
      checked={isCatchAllChecked}
      onChange={setIsCatchAllChecked}
      inputLabel={inputLabel}
    />
  );
};
